import { motion } from "framer-motion";
import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/offer.scss";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";

function OfferPage({ pageContext }) {
  const strapiHost = process.env.GATSBY_API_URL;
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          offer(locale: "${pageContext.intl.language}") {
            pageTitle
            description
            title
            offer_single {
              description
              lista_single {
                lista
              }
              title
              link
              img {
                url
              }
            }
          }
          button(locale: "${pageContext.intl.language}"){
            knowmore
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.offer);
      setButtons(items.button);
      setLoading(false);
    });
  }, []);
  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <div className="offer__wrapper">
            <h2 className="offer__title">{data.title}</h2>
            <p className="offer__description">{data.description}</p>
            <div className="offer__single">
              {data.offer_single.map(
                ({ description, img, link, lista_single, title }, key) => (
                  <div
                    className="offer__single-offer flex-column flex-xl-row"
                    key={key}
                  >
                    <div className="offer__single-wrapper">
                      <img
                        src={`${strapiHost}${img.url}`}
                        className="offer__img2"
                      />
                      <h2 className="offer__single-title">{title}</h2>
                    </div>
                    <p className="offer__single-desc1">{description}</p>
                    <ul className="offer__single-list">
                      {lista_single.map(({ lista }, key) => (
                        <li key={key}>{lista}</li>
                      ))}
                    </ul>
                    <Link to={link}>
                      <button
                        className="btn btn-lg btn-success offer__single-button"
                        type="button"
                      >
                        {buttons.knowmore}
                      </button>
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        </ShowProvider>
      )}
    </Layout>
  );
}

export default OfferPage;
